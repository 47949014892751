import { RouteComponentProps } from '@reach/router';
import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { navigate } from 'gatsby-link';
import { useMutation } from 'react-query';

import PublicLayout from '../layout/public-layout';
import { sendResetPasswordEmail } from '../apis/auth';
import { resetErrorAction } from '../state/actions/auth';
import { LOG_IN_URL, MESSAGE_STATUS, PASSWORD_RESET_SUBMIT_CONFIRMATION } from '../constants';
import { IEmailConfirmation, IForgetPasswordFormData } from '../types/models/auth';
import EmailVerificationConfirmation from '../components/user/auth/email-verification-confirmation';
import SEO from '../components/seo';

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 30px;
`;

export default function PasswordResetSubmitConfirmation({ location }: RouteComponentProps<{}>) {
  const [status, setStatus] = useState<string>('');
  const email = get(location, 'state.email');
  const dispatch = useDispatch();

  const { mutateAsync: sendEmail } = useMutation<IEmailConfirmation, unknown, IForgetPasswordFormData>(
    (formData: IForgetPasswordFormData) => sendResetPasswordEmail(formData)
  );

  const handleVerificationClick = useCallback(async () => {
    if (email) {
      try {
        await sendEmail({ email });
        setStatus(MESSAGE_STATUS.SUCCESS);
      } catch (e) {
        setStatus(MESSAGE_STATUS.ERROR);
      }
    }
  }, [email, sendEmail]);

  const handleSignIn = useCallback(() => {
    dispatch(resetErrorAction(LOG_IN_URL));
    navigate(LOG_IN_URL);
  }, [dispatch]);

  return (
    <div>
      <SEO title="Sign In" />;
      <PublicLayout>
        <Container>
          <EmailVerificationConfirmation
            headingText={PASSWORD_RESET_SUBMIT_CONFIRMATION.DISPLAY_MESSAGE}
            paragraphText={PASSWORD_RESET_SUBMIT_CONFIRMATION.INSTRUCTION_TEXT}
            linkText={PASSWORD_RESET_SUBMIT_CONFIRMATION.LINK_TEXT}
            buttonText={PASSWORD_RESET_SUBMIT_CONFIRMATION.BUTTON_TEXT}
            onButtonClick={handleSignIn}
            onLinkClick={handleVerificationClick}
            status={status}
          />
        </Container>
      </PublicLayout>
    </div>
  );
}
